import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getEditorColor,
  getEditorColorForIcon,
  renderColor,
  renderStatsBg,
} from '../../util/ColorUtil';
import { getHoverColorLocation, isItUndefined } from '../../util/DataUtil';
import { getObjectValueByMachineName } from '../../util/ObjectUtil';
import { formatCommaSeparator } from '../../util/RegexUtil';
import CtaBarButton from '../base/cta-bar-button';
import { cleanHTML } from '../utils/htmlUtils';

const TemplateStats = (props) => {
  const dataSet = {
    ...props?.facilityData,
    ...props?.metroData,
    ...props?.locationData,
    ...props?.regionData,
  };
  const isBlockWithCta = props?.children?.field_cta_bar_button_selection[0]
    ?.cta_bar_button?.field_cta_bar_button_text_1[0]?.value
    ? true
    : false;
  const statsSize =
    props?.children?.field_stats_paragraph?.stats_paragraph_type?.length;
  const iconColor = getEditorColorForIcon(
    isItUndefined(props?.children?.field_long_formatted)
  );
  const ediatbleIcon = getEditorColor(
    isItUndefined(props?.children?.field_long_formatted)
  );
  const isDescriptionAndNotHeadline =
    !props?.children?.field_long_formatted[0]?.value &&
    props?.children?.field_description[0]?.value
      ? true
      : false;

  const renderStats = (data) => {
    if (!data || data.length == 0) {
      return;
    }

    return data.map((item, index) => {
      return (
        <div className="stats-list" key={`stats-list-${index}`}>
          <span
            className={'stats-value'}
            dangerouslySetInnerHTML={{
              __html: formatCommaSeparator(
                getObjectValueByMachineName(
                  item?.field_label_text[0]?.value,
                  dataSet
                )
              ),
            }}
          />
          <span
            className={'stats-description'}
            dangerouslySetInnerHTML={{
              __html: formatCommaSeparator(
                getObjectValueByMachineName(item?.field_word[0]?.value, dataSet)
              ),
            }}
          />
        </div>
      );
    });
  };

  const renderBullets = (data) => {
    if (!data || data.length == 0) {
      return;
    }

    return data.map((item, key) => {
      return (
        <div className="bullet-items" key={`bullet-item-${key}`}>
          {props?.children?.field_font_awesome_icon[0]?.icon_name ? (
            <FontAwesomeIcon
              icon={[
                props?.children?.field_font_awesome_icon[0]?.style,
                props?.children?.field_font_awesome_icon[0]?.icon_name,
              ]}
              style={{ fill: iconColor ? iconColor : '' }}
            />
          ) : (
            <img
              style={{ filter: getHoverColorLocation(ediatbleIcon) }}
              className="checkbox"
              alt="checkbox"
              src="/images/check-box.svg"
            />
          )}
          <span className="bullet-item">{item?.value}</span>
        </div>
      );
    });
  };

  return (
    <div
      className={`template-stats ${renderStatsBg(props?.children?.field_text_color[0]?.value, props?.regionColor)} ${isBlockWithCta ? 'with-cta' : ''} ${isDescriptionAndNotHeadline ? 'without-headline' : ''}`}
    >
      <div className="inner-container">
        <div className={'template-stats-container'}>
          <div className={`template-title-div`}>
            {props?.children?.field_long_formatted[0]?.value ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    props?.children.field_long_formatted[0]?.value
                  ),
                }}
                className={`template-stats-title`}
              ></span>
            ) : (
              <></>
            )}
            {props?.children?.field_description[0]?.value ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    props?.children?.field_description[0]?.value
                  ),
                }}
                className={'template-stats-description'}
              />
            ) : (
              <></>
            )}
            {props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button
              ?.field_cta_bar_button_text_1[0]?.value ? (
              <CtaBarButton
                blockTypeClass={'stats'}
                data={
                  props?.children?.field_cta_bar_button_selection[0]
                    ?.cta_bar_button
                }
                bgColor={renderColor(
                  props?.children?.field_text_color[0]?.value,
                  props?.regionColor
                )}
              />
            ) : (
              <></>
            )}
            {props?.children?.field_bullet_item_text?.length > 0 ? (
              <div className="bullets">
                {renderBullets(props?.children?.field_bullet_item_text)}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={`stats ${statsSize == 1 ? 'single-stat' : ''}`}>
            {statsSize >= 2 ? (
              renderStats(
                props?.children?.field_stats_paragraph?.stats_paragraph_type
              )
            ) : props.children.field_stats_paragraph?.stats_paragraph_type
                ?.length > 0 ? (
              <div className="overlay">
                <img
                  className="overlay"
                  src="/images/why-super-overlay.png"
                ></img>
                {renderStats(
                  props?.children?.field_stats_paragraph?.stats_paragraph_type
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateStats;
